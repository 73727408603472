import React, { useState, useEffect } from "react";
import { Formik, ErrorMessage, Field } from "formik";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import Modal from "../../../components/Modals";
import Input from "../../../components/Input/Input";
import requests from "../../../services/requests";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";
import moment from "moment";
import Select from "react-select";
import { FiTrash } from "react-icons/fi";
import { FaPlus } from "react-icons/fa";
import * as yup from "yup";

registerLocale("pt", pt);

const ModalDriverFreightEdit = ({ actionModal, openModal, handleSuccess, selectedEntity }) => {
    const [load, setLoad] = useState(false);
    const [entity, setEntity] = useState({});
    const [drivers, setDrivers] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [trajectories, setTrajectories] = useState([]);

    const typeOptions = [
        { value: "COLETA", label: "COLETA" },
        { value: "ENTREGA", label: "ENTREGA" },
    ];

    const listDrivers = () => {
        setLoad(true);
        requests.listUsers({ role: "ROLE_DRIVER" }, 0, 99999).then(list => {
            let options = [];
            list.data.map(driver => {
                return (
                    options.push({
                        value: driver.identifier,
                        label: driver.name,
                    })
                )
            });
            setDrivers(options);
            setLoad(false);
        });
    };

    const listVehicles = () => {
        setLoad(true);
        requests.listVehicles({}, 0, 99999).then(list => {
            let options = [];
            list.data.map(vehicle => {
                return (
                    options.push({
                        value: vehicle.identifier,
                        label: vehicle.licensePlate,
                    })
                )
            });
            setVehicles(options);
            setLoad(false);
        });
    };

    const listParents = () => {
        setLoad(true);
        requests.listVehicles({}, 0, 99999).then(list => {
            let options = [];
            list.data.map(vehicle => {
                return (
                    options.push({
                        value: vehicle.identifier,
                        label: vehicle.licensePlate,
                    })
                )
            });
            setVehicles(options);
            setLoad(false);
        });
    };

    useEffect(() => {
        listDrivers();
        listVehicles();
        listParents();
    }, []);

    useEffect(() => {
        setLoad(true);
        if (selectedEntity && selectedEntity?.identifier) {
            requests.showDriverFreight(selectedEntity.identifier).then(data => {
                setTrajectories(data.trajectories);
                setEntity(data);
                setLoad(false);
            });
        }
    }, [selectedEntity]);

    return (
        <Modal
            onClose={actionModal}
            size="xs:w-12/12 lg:w-6/12 min-h-40%"
            show={openModal}
            title={
                <div className="flex items-center">
                    <h5 className={`mr-6 text-roxo_oficial font-bold`}>Edição de viagem</h5>
                </div>
            }
        >
            <>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        driver: { value: entity?.userDriverIdentifier, label: entity?.userDriverName },
                        vehicle: { value: entity?.vehicle?.identifier, label: entity?.vehicle?.licensePlate },
                        startsAt: entity?.startsAt,
                        endsAt: entity?.endsAt,
                        trajectories: trajectories,
                    }}
                    validationSchema={yup.object().shape({
                        vehicle: yup.string().nullable(),
                        parent: yup.string().nullable(),
                        startsAt: yup.string().required("Campo obrigatório.").nullable(),
                        endsAt: yup.string().nullable(),
                        trajectories: yup
                            .array()
                            .of(
                                yup.object().shape({
                                    type: yup.string().required("Campo obrigatório."),
                                    //startingFrom: yup.string().required("Campo obrigatório."),
                                    address: yup.string().required("Campo obrigatório."),
                                })
                            )
                            .required("Deve ter trajetos.")
                            .min(1, "Preencha no minímo um trajeto."),
                    })}
                    onSubmit={(values, { setFieldError, setSubmitting }) => {
                        setSubmitting(true);

                        let data = {
                            vehicle: values.vehicle.value,
                            driver: values.driver.value,
                            startsAt: values.startsAt ? moment(values.startsAt).format("DD/MM/yyyy HH:mm:ss") : null,
                            endsAt: values.endsAt ? moment(values.endsAt).format("DD/MM/yyyy HH:mm:ss") : null,
                            trajectories: values.trajectories,
                        };

                        requests
                            .editDriverFreight(entity.identifier, data)
                            .then(response => {
                                setSubmitting(false);
                                handleSuccess();
                                toast.success("Viagem editada com sucesso!");
                            })
                            .catch(error => {
                                setSubmitting(false);
                            });
                    }}
                >
                    {({ setFieldValue, handleChange, handleBlur, handleSubmit, isSubmitting, values }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="w-full">
                                <div className="flex">
                                    <div className="w-6/12">
                                        <label htmlFor="driver" className="text-roxo_oficial font-bold">
                                            Motorista
                                        </label>
                                        <Select
                                            isClearable
                                            name="driver"
                                            value={values.driver}
                                            className="block uppercase text-blueGray-600 text-xs font-bold"
                                            placeholder="Selecione um motorista"
                                            onChange={option => {
                                                setFieldValue("driver", option);
                                            }}
                                            options={drivers}
                                        />
                                        <ErrorMessage
                                            component="label"
                                            name="driver"
                                            className="text-red font-light w-full"
                                        />
                                    </div>
                                    <div className="w-6/12 ml-4">
                                        <label htmlFor="vehicle" className="text-roxo_oficial font-bold">
                                            Veículo (trazer somente vehicle do motorista selecionado)
                                        </label>
                                        <Select
                                            isClearable
                                            name="vehicle"
                                            value={values.vehicle}
                                            className="block uppercase text-blueGray-600 text-xs font-bold"
                                            placeholder="Selecione um veículo"
                                            onChange={option => {
                                                setFieldValue("vehicle", option);
                                            }}
                                            options={vehicles}
                                        />
                                        <ErrorMessage
                                            component="label"
                                            name="vehicle"
                                            className="text-red font-light w-full"
                                        />
                                    </div>
                                </div>
                                <div className="flex mt-4">
                                    <div className="w-6/12">
                                        <label htmlFor="startingFrom" className="text-roxo_oficial font-bold">
                                            Destino inicial
                                        </label>
                                        <Input
                                            name="startingFrom"
                                            id="startingFrom"
                                            type="text"
                                            placeholder="Digite o destino inicial"
                                            value={values.startingFrom}
                                        />
                                        <ErrorMessage
                                            component="label"
                                            name="startingFrom"
                                            className="text-red font-light w-full"
                                        />
                                    </div>
                                    <div className="w-6/12 ml-4">
                                        <label htmlFor="address" className="text-roxo_oficial font-bold">
                                            Destino final
                                        </label>
                                        <Input
                                            name="address"
                                            id="address"
                                            type="text"
                                            placeholder="Digite o destino inicial"
                                            value={values.address}
                                        />
                                        <ErrorMessage
                                            component="label"
                                            name="address"
                                            className="text-red font-light w-full"
                                        />
                                    </div>
                                </div>
                                <div className="flex mt-3">
                                    <div className="w-6/12">
                                        <label htmlFor="startsAt" className="text-roxo_oficial font-bold">
                                            Data e horário inicial
                                        </label>
                                        <DatePicker
                                            locale="pt"
                                            selected={values.startsAt && new Date(values.startsAt)}
                                            dateFormat="Pp"
                                            className="rounded-md border border-C2 h-9 py-1 px-5"
                                            name="startsAt"
                                            onChange={date => {
                                                setFieldValue("startsAt", date);
                                                setFieldValue("endsAts", null);
                                            }}
                                            placeholderText="Selecione uma data e horário inicial"
                                            showTimeSelect
                                            isClearable
                                        />
                                        <ErrorMessage
                                            component="p"
                                            className="text-red mb-4 font-light text-xs"
                                            name="startsAt"
                                        />
                                    </div>
                                    <div className="w-6/12 ml-4">
                                        <label htmlFor="endsAt" className="text-roxo_oficial font-bold">
                                            Data e horário final
                                        </label>
                                        <DatePicker
                                            isClearable
                                            minDate={values.startsAt}
                                            locale="pt"
                                            selected={values.endsAt && new Date(values.endsAt)}
                                            dateFormat="Pp"
                                            className="rounded-md border border-C2 h-9 py-1 px-5"
                                            name="endsAt"
                                            placeholderText={`${!values.startsAt
                                                ? "Primeiro selecione uma data e horário inicial"
                                                : "Selecione uma data e horário final"
                                                }`}
                                            showTimeSelect
                                            onChange={date => {
                                                setFieldValue("endsAt", date);
                                            }}
                                            disabled={values.startsAt ? false : true}
                                        />
                                        <ErrorMessage
                                            component="p"
                                            className="text-red mb-4 font-light text-xs"
                                            name="endsAt"
                                        />
                                    </div>
                                </div>
                                <div className="flex mt-4">
                                    <div className="w-6/12">
                                        <div className="flex justify-between mt-4">
                                            {`${values.trajectories.length === 0
                                                ? "Necessário preencher pelo menos um trecho"
                                                : `${values.trajectories.length} trecho${values.trajectories.length > 1 ? "s" : ""
                                                } inserido${values.trajectories.length > 1 ? "s" : ""}`
                                                }`}
                                        </div>
                                    </div>
                                    <div className="w-6/12">
                                        <div className="mt-4 float-right">
                                            <label>
                                                Adicionar novo trecho
                                                <button
                                                    className="cursor-pointer inline-block bg-green-400 p-1 rounded text-white ml-3 text-sm"
                                                    onClick={() => {
                                                        setFieldValue("trajectories", [
                                                            ...values.trajectories,
                                                            {
                                                                //startingFrom: "",
                                                                address: "",
                                                                type: "",
                                                            },
                                                        ]);
                                                    }}
                                                >
                                                    <FaPlus />
                                                </button>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="flex-wrap overflow-x-auto mt-5"
                                    style={values.trajectories.length > 5 ? { height: 300 } : {}}
                                >
                                    {values.trajectories.length > 0 &&
                                        values.trajectories.map((trajectory, index) => (
                                            <>
                                                <div className="flex mt-4">
                                                    <div className="w-2/12">
                                                        <label htmlFor="cteNumber" className="text-roxo_oficial font-bold">
                                                            TRECHO {index + 1} - TIPO
                                                        </label>
                                                        <button
                                                            className="ml-2"
                                                            onClick={e => {
                                                                setFieldValue(
                                                                    "trajectories",
                                                                    values.trajectories.filter((_, key) => key !== index)
                                                                );
                                                            }}
                                                        >
                                                            <FiTrash size={15} color="red" />
                                                        </button>
                                                        {/*<Input
                                                            value={trajectory.cteNumber}
                                                            className="w-full rounded-md border py-1 px-5 border-C2 h-9"
                                                            name={`trajectories.${index}.cteNumber`}
                                                            placeholder="Preencha o CTE"
                                                            type="text"
                                                        />
                                                        <ErrorMessage
                                                            component="p"
                                                            className="mt-2 text-red mb-4 font-light text-xs"
                                                            name={`trajectories.${index}.cteNumber`}
                                                        />*/}
                                                        <Select
                                                            name={`trajectories.${index}.type`}
                                                            className="block uppercase text-blueGray-600 text-xs font-bold"
                                                            options={typeOptions}
                                                            value={typeOptions.filter(
                                                                option => option.value === trajectory.type
                                                            )}
                                                            onChange={option => {
                                                                values.trajectories[index].type = option.value;
                                                                setFieldValue("trajectories", values.trajectories);
                                                            }}
                                                            placeholder=""
                                                        />
                                                        <ErrorMessage
                                                            component="p"
                                                            className="mt-2 text-red mb-4 font-light text-xs"
                                                            name={`trajectories.${index}.type`}
                                                        />
                                                    </div>
                                                    {/*<div className="w-5/12 ml-4">
                                                        <label htmlFor="driver" className="text-roxo_oficial font-bold">
                                                            Trecho inicial
                                                        </label>
                                                        <Field
                                                            as="textarea"
                                                            rows="2"
                                                            className="w-full rounded-md border py-1 px-2 border-C2"
                                                            name={`trajectories.${index}.startingFrom`}
                                                            onChange={handleChange}
                                                            value={trajectory.startingFrom}
                                                            placeholder="Preencha o trecho inicial"
                                                        />
                                                        <ErrorMessage
                                                            component="p"
                                                            className="text-red mb-4 font-light text-xs"
                                                            name={`trajectories.${index}.startingFrom`}
                                                        />
                                                    </div>*/}
                                                    <div className="w-5/12 ml-4">
                                                        <label htmlFor="driver" className="text-roxo_oficial font-bold">
                                                            Endereço
                                                        </label>
                                                        <Field
                                                            as="textarea"
                                                            rows="2"
                                                            className="w-full rounded-md border py-1 px-2 border-C2"
                                                            name={`trajectories.${index}.address`}
                                                            onChange={handleChange}
                                                            value={trajectory.address}
                                                            placeholder="Preencha o trecho inicial"
                                                        />
                                                        <ErrorMessage
                                                            component="p"
                                                            className="text-red font-light text-xs"
                                                            name={`trajectories.${index}.address`}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                </div>
                                <div className="flex mt-4">
                                    {/*<div className="w-6/12">
                                        <label htmlFor="driver" className="text-roxo_oficial font-bold">
                                            Trecho inicial
                                        </label>
                                        <Field
                                            as="textarea"
                                            rows="2"
                                            disabled
                                            className="w-full rounded-md border py-1 px-2 border-C2"
                                            onChange={handleChange}
                                            value={values.trajectories[0]?.startingFrom}
                                            placeholder="Preenchido automaticamente"
                                        />
                                    </div>*/}
                                    <div className="w-6/12 ml-4">
                                        <label htmlFor="vehicle" className="text-roxo_oficial font-bold">
                                            Trecho final
                                        </label>
                                        <Field
                                            as="textarea"
                                            rows="2"
                                            disabled
                                            className="w-full rounded-md border py-1 px-2 border-C2"
                                            onChange={handleChange}
                                            value={values.trajectories.slice(-1)[0]?.address}
                                            placeholder="Preenchido automaticamente"
                                        />
                                    </div>
                                </div>
                            </div>
                            <button
                                className=" bg-roxo_oficial text-white py-2 px-5 rounded-md float-right mt-10 mb-10"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? (
                                    <>
                                        Salvando <ClipLoader size={10} loading={isSubmitting} />
                                    </>
                                ) : (
                                    <>Salvar rascunho</>
                                )}
                            </button>
                        </form>
                    )}
                </Formik>
            </>
        </Modal>
    );
};

export default ModalDriverFreightEdit;
